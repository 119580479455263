// Den.js
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../Features/ProjectCards";
import Particle from "../Particle";
import Agent01Image from "../../Assets/Projects/SinglishConnect.jpeg";
import Agent02Image from "../../Assets/Projects/ABC-Logo.png";
import Agent03Image from "src/Assets/Agents/AMA/introAMA6.png";
// import Agent04Image from "src/Assets/Agents/AMA/introAMA5.png";
// import Agent03Image from "../../Assets/Brand/Whiteicon.png";

function Den() {
  return (
    <Container fluid className="project-section" style={{ textAlign: "center"}}>
      <Container>
        <Particle />
        <h1 className="project-heading">
          Welcome to the <strong className="purple">Den </strong>
        </h1>
        <p style={{ color: "white", fontSize: "20px" }}>
          Select a demo to explore.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "5px",  }}>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Agent01Image}
              isBlog={false}
              title="Dev team - Alpha"
              description="Internal"
              link="/agent01"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Agent02Image}
              isBlog={false}
              title="AromaAI"
              description="for Aroma Bliss Ceylon"
              link="/agent02"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Agent03Image}
              isBlog={false}
              title="Imagine"
              description="Imagine Engine"
              link="/agent04"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Agent04Image}
              isBlog={false}
              title="Test"
              description="Test"
              link="/agent04"
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Den;
